<template>
  <!-- 监控--- NVR管理 -->
  <div style="margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="NVR：">
        <el-input v-model="queryForm.nvr_name" maxlength="30" @keyup.enter.native="onSubmit" placeholder="请输入NVR名称"
          @input="(e) => (queryForm.nvr_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <div class="box-card">
      <le-card title="NVR管理">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('56e36cfeba9748dcad809586')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="addNewItem"
              v-if='this.getUserInfo.user_name != "admin" && isShowBtn("bf19d908a41f48b69996b648")'>新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
           <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="edit(row)"
                  v-if="isShowBtn('7655bdc9a8e74a66b1f63fe6')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="nvr_name" label="NVR名称"></el-table-column>
            <el-table-column prop="nvr_brand" label="品牌"></el-table-column>
            <el-table-column prop="nvr_ip" label="IP地址"></el-table-column>
            <el-table-column prop="nvr_port" label="端口"></el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="edit(scope.row)"
                  v-if="isShowBtn('7655bdc9a8e74a66b1f63fe6')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增NVR -->
    <lebo-dialog append-to-body :title="title" :isShow="isAddVisible" width="40%" @close="closeAddVisible" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="NVR名称：" prop="nvr_name">
          <el-input maxlength="30" v-model.trim="addForm.nvr_name" placeholder="请输入NVR名称"
            @input="(e) => (addForm.nvr_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="品牌：" prop="nvr_brand">
          <el-select popper-class="my-select" style="width: 100%;" v-model="addForm.nvr_brand" placeholder="请选择设备品牌">
            <el-option label="海康威视" value="海康威视"></el-option>
            <el-option label="大华" value="大华"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item maxlength="10" label="用户名：" prop="nvr_user_name">
          <el-input maxlength="15" v-model.trim="addForm.nvr_user_name" placeholder="请输入用户名"
            @input="(e) => (addForm.nvr_user_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="nvr_password">
          <el-input maxlength="16" v-model.trim="addForm.nvr_password" placeholder="请输入密码"
            @input="(e) => (addForm.nvr_password = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="IP地址：" prop="nvr_ip">
          <el-input maxlength="32" v-model.trim="addForm.nvr_ip" placeholder="请输入IP地址"
            @input="(e) => (addForm.nvr_ip = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="端口：" prop="nvr_port">
          <el-input maxlength="8" v-model.trim="addForm.nvr_port" placeholder="请输入端口号"
            @input="(e) => (addForm.nvr_port = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="场景：" prop="scene_id">
          <el-select popper-class="my-select" style="width: 100%;" filterable v-model="addForm.scene_id"
            placeholder="请选择场景...">
            <el-option v-for="item in sceneSelectList" :key="item.Id" :label="item.name" :value="item.Id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="addAndEdit" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getNVRList, setNVRSave, getNVRDetail, DelNVR, getSceneList, getBindScene } from '@/api/monitoring'
export default {
  data () {
    // 自定义验证规则  用户密码
    // var psdRule = (rule, value, callback) => {
    //   const psdReg = /^[\w]{6,14}$/;
    //   if (psdReg.test(value)) {
    //     return callback();
    //   }
    //   callback(new Error());
    // };
    return {
      queryForm: {
        nvr_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      tableData: [],
      multipleSelection: [],
      // 是否展示新增对话框
      isAddVisible: false,
      title: '',
      // 新增表单
      addForm: {
        Id: '', // nvr id
        nvr_name: '', // NVR名称
        nvr_brand: '', // NVR品牌
        nvr_user_name: '', // NVR用户名
        nvr_password: '', // NVR密码
        nvr_ip: '', // NVR登录地址
        nvr_port: '', // NVR端口号
        scene_id: '' // 场景id
      },
      // 场景下拉列表
      sceneSelectList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: 'NVR名称',
            prop: 'nvr_name'
          }, {
            label: '品牌',
            prop: 'nvr_brand'
          }, {
            label: 'IP地址',
            prop: 'nvr_ip'
          }, {
            label: '端口',
            prop: 'nvr_port',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      addFormRules: {
        nvr_name: [
          { required: true, message: '请输入NVR名称', trigger: 'blur' }
        ],
        nvr_brand: [
          { required: true, message: '请选择设备品牌', trigger: 'change' }
        ],
        nvr_user_name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        nvr_password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
          // {
          //   validator: psdRule,
          //   message: " 6~14个字符，包含字母、数字，下划线，区分大小写",
          //   trigger: "blur",
          // },
        ],
        nvr_ip: [
          { required: true, message: '请输入IP地址', trigger: 'blur' },
          { pattern: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/, message: '请输入正确的ip地址', trigger: 'blur' }
        ],
        nvr_port: [
          { required: true, message: '请输入端口号', trigger: 'blur' },
          { pattern: /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/, message: '请输入正确的端口号' }
        ],
        scene_id: [
          { required: true, message: '请选择场景', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  created () {
    this.fnGetNVRlist()
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  mounted () { },
  methods: {
    // 获取列表数据
    async fnGetNVRlist () {
      const res = await getNVRList(this.queryForm)
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      // console.log(this.queryForm);
      this.fnGetNVRlist()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetNVRlist()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetNVRlist()
    // },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetNVRlist()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetNVRlist()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('是否确认删除此NVR，删除后此NVR下的数据也会删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i].Id)
            if (arr.length === this.multipleSelection.length) {
              obj.Id = arr.join(',')
              this.fnDelItem(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除！')
        })
      } else {
        this.$msg.warning('请选择要删除的NVR')
      }
    },
    async fnDelItem (obj) {
      const res = await DelNVR(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetNVRlist()
    },
    // // 新增 对话框
    addNewItem () {
      this.fnGetSceneSelectList()
      this.isAddVisible = true
      this.title = '新增NVR'
    },
    // 获取场景下拉列
    async fnGetSceneSelectList () {
      const res = await getBindScene()
      console.log('获取场景下拉列----', res)
      this.sceneSelectList = res.Code === 200 ? res.Data : []
    },
    // 编辑
    edit (item) {
      console.log('场景数据', item)
      this.fnGetSceneSelectList()
      this.getNVREdit(item.Id)
      this.isAddVisible = true
      this.title = '编辑NVR'
    },
    // 获取编辑列表
    async getNVREdit (id) {
      var that = this
      var obj = {}
      obj.Id = id
      const ret = await getNVRDetail(obj)
      if (ret.Code === 200) {
        console.log(ret.Data)
        that.addForm.Id = ret.Data.Id
        that.addForm.nvr_name = ret.Data.nvr_name
        that.addForm.nvr_brand = ret.Data.nvr_brand
        that.addForm.nvr_user_name = ret.Data.nvr_user_name
        that.addForm.nvr_password = ret.Data.nvr_password
        that.addForm.nvr_ip = ret.Data.nvr_ip
        that.addForm.nvr_port = ret.Data.nvr_port
        that.addForm.scene_id = ret.Data.scene_id
        // that.addForm.category_id = ret.Data.category_id
        // setTimeout(() => {
        //   var id = that.addForm.category_id ? that.addForm.category_id : that.addForm.scene_id
        //   // that.$nextTick(function() {
        //   // 设置选中的节点
        //   that.$refs.tree.setCheckedKeys([id]);
        //   // 根据 data 或者 key 拿到 Tree 组件中的 node
        //   var data = that.$refs.tree.getNode(id)
        //   console.log(data)
        //   that.addForm.scene_name = data.label
        //   // })
        // }, 300)
      }
    },
    // // 当复选框被点击的时候触发
    // handleCheckChangeArea(data) {
    //   console.log(data);
    //   var labvalojb = data; // 暂存选中节点
    //   this.$refs.tree.setCheckedKeys([]); // 删除所有选中节点
    //   this.$refs.tree.setCheckedNodes([labvalojb]); // 选中已选中节点
    //   this.addForm.scene_id = data.scene_id ? data.scene_id : data.Id
    //   this.addForm.scene_name = data.name
    //   this.addForm.category_id = data.scene_id ? data.Id : ''
    //   this.$refs.treeSelect.blur()
    // },
    // 确定新增或编辑
    addAndEdit () {
      // 验证表单
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var that = this
          var obj = {}
          obj.Id = that.addForm.Id ? that.addForm.Id : ''
          obj.nvr_name = that.addForm.nvr_name
          obj.nvr_brand = that.addForm.nvr_brand
          obj.nvr_user_name = that.addForm.nvr_user_name
          obj.nvr_password = that.addForm.nvr_password
          obj.nvr_ip = that.addForm.nvr_ip
          obj.nvr_port = that.addForm.nvr_port
          obj.scene_id = that.addForm.scene_id
          // obj.category_id = that.addForm.category_id;
          this.fnsetNVRSave(obj)
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    async fnsetNVRSave (obj) {
      const res = await setNVRSave(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      if (obj.Id) {
        this.fnGetNVRlist()
      } else {
        this.onSubmit()
      }
      this.isAddVisible = false
    },
    // 关闭对话框重置数据
    closeAddVisible () {
      this.isAddVisible = false
      this.$refs.addFormRef.resetFields()
      this.addForm.Id = ''
      this.addForm.scene_id = ''
      this.addForm.scene_name = ''
      this.addForm.category_id = ''
      console.log(this.addForm)
    }
  }
}
</script>

<style scoped lang="less"></style>
